import React from "react"
import { MultiLangField } from "skillstrainer-resource-library"

export default function StoryCard(props) {
  const { data } = props
  const { profileImage, name, job, place_of_job, age, earning } =
    data.attributes
  return (
    <div className="bg-white p-1 w-60 m-2 rounded-md story-card">
      <img src={profileImage.data[0].attributes.url || profileImage[0].url} />
      <p className="text-sm text-gray-700 mt-3">
        <MultiLangField name={"Journey_users_" + data.id}>
          {`${name}, ${age} yrs, ${job} @ ${place_of_job}`}
        </MultiLangField>
        ,<MultiLangField name={"Income:åÊ"}> Income: </MultiLangField>
        <span className="red-dark">Rs.{earning}+</span>
      </p>
    </div>
  )
}
