import React, {
  useContext,
  useState,
  Fragment,
  useEffect,
  useMemo,
} from "react"
import Marquee from "react-fast-marquee"
import { Link, navigate } from "gatsby"
import { Listbox, Transition } from "@headlessui/react"
import { CheckIcon } from "@heroicons/react/solid"
import Partner1 from "../assets/image/partner1.png"
import Partner2 from "../assets/image/partner2.png"
import Partner3 from "../assets/image/partner3.png"
import Partner4 from "../assets/image/partner4.png"
import Partner5 from "../assets/image/partner5.png"
import Partner6 from "../assets/image/partner6.png"
import Partner7 from "../assets/image/partner7.png"
import Partner8 from "../assets/image/partner8.png"
import Partner9 from "../assets/image/partner9.png"
import Partner10 from "../assets/image/partner10.png"
import Partner11 from "../assets/image/partner11.png"
import Partner12 from "../assets/image/partner12.png"
import Partner13 from "../assets/image/partner13.png"
import Partner14 from "../assets/image/partner14.png"
import Partner15 from "../assets/image/partner15.png"
import Partner16 from "../assets/image/partner16.png"
import Partner17 from "../assets/image/partner17.png"
import Partner18 from "../assets/image/partner18.png"
import Partner20 from "../assets/image/partner20.png"
import Partner21 from "../assets/image/partner21.png"
import Circle from "../assets/image/home-circle.svg"
import EnglishStarLogo from "../assets/image/english-star-logo.jpg"
import Career from "../assets/image/career.png"
import Down from "../assets/image/down.svg"
import Search from "../assets/image/search.svg"
import Ruppee from "../assets/image/inr.svg"
import RuppeeGray from "../assets/image/inr-gray.svg"
import BottomNav from "../components/bottomNav"
import "../data.json"
import Layout from "../components/layout"
import innerText from "react-innertext"
import NewImageSlider from "../components/newImageSlider"
import { StateContext } from "../context/stateProvider"
import { MultiLangBody, MultiLangField } from "skillstrainer-resource-library"
import StoryCard from "../components/StoryCard"
import {
  webappHost,
  englishStarCourseId,
  userCountDiscrepancy,
} from "../../config"
import { NavigationContext } from "../context/Navigation"
import userSvc from "../services/User"
import InstructorSvc from "../services/Instructor"
import { CourseContext } from "../context/Course"
import CourseCard from "../components/CourseCard"
import courseSvc from "../services/Course"
import _ from "lodash"
import Loader from "../components/Loader"
import { toIndianNumber } from "../utils/object"

const { coursesByField } = require("../data.json")

const IndexPage = ({ pageContext }) => {
  const allCourses = {
    categoryName: "All",
    id: -1,
    numCourses: 0,
  }
  const { language, setCourseName, setCategory } = useContext(StateContext)
  const { setPath } = useContext(NavigationContext)
  const { fetchAllCoursesUsers } = useContext(CourseContext)

  const {
    usersCount,
    coursesCount,
    instructorsCount,
    coursesByCategories,
    sliders,
    stories,
  } = require("../data.json")

  const [courses, setCourses] = useState(sortEnglishCourse(coursesByField))

  useEffect(() => {
    ;(async () => {
      const allCourses = await courseSvc.fetchAllCourses().catch(err => {
        return []
      })

      setCourses(sortEnglishCourse(allCourses))
    })()
  }, [])

  const [coursesName, setCoursesName] = useState("")
  const [active, setActive] = useState(false)
  const [categories, setCategories] = useState(
    coursesByCategories && coursesByCategories[0]
  )

  const [currentCourseCount, setCurrentCourseCount] = useState(coursesCount)
  const [currentUsersCount, setUsersCount] = useState(usersCount)
  const [currentInstructorCount, setCurrentInstructorCount] =
    useState(instructorsCount)
  useEffect(() => {
    //Get Total Courses
    courseSvc.getTotalCourses().then(setCurrentCourseCount).catch(console.error)

    //Get Total Partners
    InstructorSvc.getTotalInstructors()
      .then(setCurrentInstructorCount)
      .catch(console.error)

    // Get total users
    userSvc
      .getTotalUsers()
      .then(count => setUsersCount(count + userCountDiscrepancy))
      .catch(console.error)

    // Get users by courses
    fetchAllCoursesUsers()
  }, [])

  const handleSubmit = e => {
    e.preventDefault()
    setCourseName(coursesName)
    setCategory(categories)
    categories.id == -1
      ? navigate(`/courses`)
      : navigate(`/courses/${categories.id}`)
  }

  const { key: pageKey, lang } = pageContext

  return (
    // <TinaProvider cms={cms}>
    <MultiLangBody _key={pageKey}>
      <Layout
        title="SkillsTrainer - World's Largest Vocational Skilling & Certification Portal"
        lang={lang}
      >
        <div className="mb-20">
          <BottomNav></BottomNav>
          <div className="bg-light px-10 lg:px-0">
            <div className="flex flex-col xl:flex-row content mx-auto">
              <div className="width-home1">
                <div className="xl:pr-0 py-20 xl:pt-60 relative">
                  <img src={Circle} className="absolute top-24 -left-20" />
                  <div className="home-text mt-24 xl:my-0">
                    <p className="red-dark">
                      <MultiLangField name={"hero_Title1"}>
                        World's Largest
                      </MultiLangField>
                    </p>

                    <p className="blue-dark2 my-3">
                      <MultiLangField name={"hero_Title2"}>
                        Vocational Skilling
                      </MultiLangField>
                    </p>
                    <p className="blue-dark2">
                      <MultiLangField name={"hero_Title3"}>
                        & Certification Portal
                      </MultiLangField>
                    </p>
                  </div>
                  <p className="blue-dark3 text-sm mt-8 mb-4">
                    <MultiLangField name={"hero_SubTitle"}>
                      Make your career & change your families life!
                    </MultiLangField>
                  </p>
                  <div className="w-28 div-height bg-red-500"></div>
                  <div className="flex item-center my-12 mt-10 text-lg">
                    <div>
                      {/* <p className="red-dark ">5,82,365</p>
                  <p className="blue-dark2 mt-2">{learners}</p> */}

                      <p className="red-dark ">
                        {toIndianNumber(currentUsersCount)}
                      </p>
                      <p className="blue-dark2 ">
                        <MultiLangField name={"hero_Learners"}>
                          Learners
                        </MultiLangField>
                      </p>
                    </div>
                    <div className="mx-10">
                      {/* <p className="red-dark">80+</p>
                    <p className="blue-dark2 mt-2">{courses}</p> */}
                      <p className="red-dark">
                        {toIndianNumber(currentCourseCount)}
                      </p>
                      <p className="blue-dark2 ">
                        <MultiLangField name={"hero_Courses"}>
                          Courses
                        </MultiLangField>
                      </p>
                    </div>
                    <div>
                      {/* <p className="red-dark">200+</p>
                  <p className="blue-dark2 mt-2">{partners}</p> */}
                      <p className="red-dark">200+</p>
                      <p className="blue-dark2 ">
                        <MultiLangField name={"hero_Partners"}>
                          Partners
                        </MultiLangField>
                      </p>
                    </div>
                    <div className="ml-10">
                      {/* <p className="red-dark">80+</p>
                    <p className="blue-dark2 mt-2">{courses}</p> */}
                      <p className="red-dark">
                        {toIndianNumber(currentInstructorCount)}
                      </p>
                      <p className="blue-dark2 ">
                        <MultiLangField name={"hero_Instructors"}>
                          Trainers
                        </MultiLangField>
                      </p>
                    </div>
                  </div>
                  <div className="w-full flex flex-col sm:flex-row items-center justify-between sm:bg-white sm:border-2 p-2 sm:p-1 sm:rounded-lg">
                    <div className="flex items-center w-full home-input">
                      <img src={Search} className="ml-1 h-4" />

                      <input
                        name="courseName"
                        type="text"
                        className="w-full ml-2 sm:border-r py-1 sm:border-gray-600 outline-none mr-3 text-sm"
                        // used react-innerText module to get innertext
                        placeholder={innerText(
                          <MultiLangField name={"Searchbar"}>
                            What do you want to learn?
                          </MultiLangField>
                        )}
                        onChange={e => setCoursesName(e.target.value)}
                      />
                    </div>
                    <div className="flex items-center mt-10 sm:mt-0 w-full sm:w-auto">
                      <div className="w-full z-10">
                        {/* <Listbox value={selected} onChange={setSelected}> */}
                        <Listbox value={categories} onChange={setCategories}>
                          <div className="relative mt-1">
                            <Listbox.Button className="rounded-lg relative w-full px-3 py-4 sm:py-3 pl-1 pr-10 text-left bg-white cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
                              <span className="block truncate text-sm blue-dark1">
                                {/* {selected?.categoryName} */}
                                {categories?.categoryName}
                              </span>
                              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <img src={Down} className="h-6" />
                              </span>
                            </Listbox.Button>
                            <Transition
                              as={Fragment}
                              enter="transition duration-100 ease-out"
                              enterFrom="transform scale-95 opacity-0"
                              enterTo="transform scale-100 opacity-100"
                              leave="transition duration-75 ease-out"
                              leaveFrom="transform scale-100 opacity-100"
                              leaveTo="transform scale-95 opacity-0"
                            >
                              <Listbox.Options className="absolute left-0 sm:-left-16 select-width py-1 mt-1 overflow-auto scroll text-sm bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {[allCourses]
                                  .concat(coursesByCategories)
                                  ?.map(category => {
                                    const { categoryName, id } = category

                                    return (
                                      <Listbox.Option
                                        // key={person.id}
                                        key={id}
                                        className={({ active }) =>
                                          `${
                                            active
                                              ? "text-yellow-900 bg-yellow-100"
                                              : "text-gray-900"
                                          }
                          cursor-default select-none relative py-2 pl-10 pr-4`
                                        }
                                        value={category}
                                        // value={person?.categoryName}
                                      >
                                        {({ selected, active }) => (
                                          <>
                                            <span
                                              className={`${
                                                selected
                                                  ? "font-medium"
                                                  : "font-normal"
                                              } block truncate text-md pl-4`}
                                            >
                                              {categoryName}
                                              {/* {person.categoryName} */}
                                            </span>

                                            {selected ? (
                                              <span
                                                className={`${
                                                  active
                                                    ? "text-yellow-600"
                                                    : "text-yellow-600"
                                                }
                                absolute inset-y-0 left-0 flex items-center pl-3`}
                                              >
                                                <CheckIcon
                                                  className="w-8 h-8"
                                                  aria-hidden="true"
                                                />
                                              </span>
                                            ) : null}
                                          </>
                                        )}
                                      </Listbox.Option>
                                    )
                                  })}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </Listbox>
                      </div>
                      <button
                        // type="submit"
                        className="bg-orange text-white font-semibold rounded-lg p-3 ml-5 sm:ml-0"
                        onClick={handleSubmit}
                      >
                        <MultiLangField name={"SearchButton"}>
                          Search
                        </MultiLangField>
                      </button>
                    </div>
                  </div>
                  {/* <div className="my-6 max-w-max mx-auto">
                    <button className="button btn-blue">Search</button>
                  </div> */}
                </div>
              </div>
              <div className="width-home2 h-full hidden xl:block relative pl-20">
                <div className="slider">
                  <NewImageSlider slides={sliders}></NewImageSlider>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-start flex-col lg:flex-row pb-14 content mx-auto px-10 lg:px-0">
            <div className="w-full lg:w-1/5 h-40 mt-8">
              <div
                className="text-3xl blue-dark1 font-semibold my-6"
                // dangerouslySetInnerHTML={{ __html: SkillsTrainerPartners }}
              >
                {/* <h1>Skills Trainer</h1>
              <p>Partners</p> */}
                <p>
                  <MultiLangField name={"Partners1"}>
                    SkillsTrainer
                  </MultiLangField>
                </p>
                <p>
                  <MultiLangField name={"Partners2"}>Partners</MultiLangField>
                </p>
              </div>
              <div className="w-14 h-1 bg-red-dark"></div>
            </div>
            <div className="w-full lg:w-4/5">
              <div className="lg:mr-4 mt-3 flex items-center height-partner">
                <Marquee speed={50}>
                  {[
                    Partner17,
                    Partner18,
                    Partner20,
                    Partner2,
                    Partner4,
                    Partner6,
                    Partner7,
                    Partner8,
                    Partner9,
                    Partner10,
                    Partner11,
                    Partner12,
                    Partner13,
                    Partner14,
                    Partner15,
                    Partner16,
                    Partner21,
                    Partner1,
                    Partner3,
                    Partner5,
                  ].map(i => {
                    return (
                      <img src={i} style={{ flex: "none" }} className="mr-20" />
                    )
                  })}
                </Marquee>
              </div>
              {/* <div className="flex items-center my-16 ml-20">
              <div className="bg-red-dark w-24 h-1.5"></div>
              <div className="bg-gray-500 w-24 h-0.5"></div>
            </div> */}
            </div>
          </div>

          <section className="journey w-full content sm:mx-auto ">
            <div className="text-center text-4xl font-semibold px-10 lg:px-0">
              {/* <p dangerouslySetInnerHTML={{ __html: YourJourney }}>
                 <span className="blue-dark1">Your Journey</span>
              <div className="mt-5">
                <span className="blue-dark1">For A</span>
                <span className="red-dark">{} Better Life..</span>
              </div> 
           



              </p> */}

              <span className="blue-dark1">
                <MultiLangField name={"Journey_Title"}>
                  Your Journey
                </MultiLangField>
              </span>
              {/* <div className="mt-5">
                <span className="blue-dark1">For A</span>
                <span className="red-dark">Better Life..</span>
              </div> */}
            </div>

            {/* <Link
              to={
                language == "en"
                  ? "/english-star"
                  : "/" + language + "/english-star"
              }
            >
              <MultiLangField name={"english-star"}>Contact Us</MultiLangField>
            </Link> */}

            <div className="bg-blue-grad lg:mx-20 sm:px-10 mt-18 md:mt-44 pt-10 md:pt-96 lg:pt-36 pb-10 relative">
              {/* <div
                className="flex flex-wrap justify-center md:absolute md:-top-32 md:left-10 md:right-10 lg:shadow-lg divide-x"
                dangerouslySetInnerHTML={{ __html: fivePoints }}
              > */}
              <div className="flex flex-wrap justify-center md:absolute md:-top-32 md:left-0 md:right-0 lg:shadow-lg divide-x">
                <div className="border-journey-1 w-64 sm:w-72 lg:w-1/5 h-44 p-3 bg-white">
                  <div className="h-8 w-8 text-xl p-1 flex items-center justify-center rounded-full bg-blue-dark1 text-white text-center">
                    1
                  </div>
                  <p className="text text-md mt-8">
                    <MultiLangField name={"Journey_benefits1"}>
                      SkillsTrainer Course & Assessment
                    </MultiLangField>
                  </p>
                </div>
                <div className="border-journey-2 w-64 sm:w-72 lg:w-1/5 h-44 p-3 bg-white">
                  <div className="h-8 w-8 text-xl p-1 flex items-center justify-center rounded-full bg-green-light text-white text-center">
                    2
                  </div>
                  <p className="text text-md mt-8">
                    <MultiLangField name={"Journey_benefits2"}>
                      Govt. Approved/ Industry Validated Certification
                    </MultiLangField>
                  </p>
                </div>
                <div className="border-journey-3 w-64 sm:w-72 lg:w-1/5 h-44 p-3 bg-white">
                  <div className="h-8 w-8 text-xl p-1 flex items-center justify-center rounded-full bg-orange-light2 text-white text-center">
                    3
                  </div>
                  <p className="text text-md mt-8">
                    <MultiLangField name={"Journey_benefits3"}>
                      Job or Your Business
                    </MultiLangField>
                  </p>
                </div>
                <div className="border-journey-4 w-64 sm:w-72 lg:w-1/5 h-44 p-3 bg-white">
                  <div className="h-8 w-8 text-xl p-1 flex items-center justify-center rounded-full bg-orange-light1 text-white text-center">
                    4
                  </div>
                  <p className="text text-md mt-8">
                    <MultiLangField name={"Journey_benefits4"}>
                      Another SkillsTrainer course
                    </MultiLangField>
                  </p>
                </div>
                <div className="border-journey-5 w-64 sm:w-72 lg:w-1/5 h-44 p-3 bg-white">
                  <div className="h-8 w-8 text-xl p-1 flex items-center justify-center rounded-full bg-red-dark text-white text-center">
                    5
                  </div>
                  <p className="text text-md mt-8">
                    <MultiLangField name={"Journey_benefits5"}>
                      A Better Job or a Bigger Business
                    </MultiLangField>
                  </p>
                </div>
              </div>

              <div className="flex flex-col xl:flex-row items-center justify-between">
                <div className="flex flex-col lg:flex-row items-center mt-10 lg:mt-0 story-cards">
                  {stories.data.slice(0, 3).map(story => (
                    <StoryCard data={story} />
                  ))}
                </div>
                <div className="flex flex-col items-start xl:items-end justify-between my-16 xl:my-0">
                  <div></div>
                  {/* dangerouslySetInnerHTML={{ __html: ThisCouldBeYou }} */}
                  <p className="blue-dark2 text-4xl font-semibold xl:text-right">
                    <MultiLangField name={"Journey_aside1a"}>
                      This Could
                    </MultiLangField>{" "}
                    <br />
                    <MultiLangField name={"Journey_aside1b"}>
                      be you!
                    </MultiLangField>
                  </p>
                  {/* data cmng from strapi in this div */}
                  <div>
                    <button className="bg-orange text-white font-semibold rounded-lg p-3 mt-8 text-md">
                      <a href={webappHost} target="_blank">
                        <MultiLangField name={"Journey_button"}>
                          Join for Free
                        </MultiLangField>
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="Offers">
            <div className="mt-32 mb-10 px-10 lg:px-0">
              <p className="text-4xl font-semibold text-japanese_indigo text-center">
                <MultiLangField name={"topCourses_Title1"}>
                  Top Courses & Special{" "}
                </MultiLangField>
                <span className="text-orange">
                  <MultiLangField name={"topCourses_Title2"}>
                    Offers
                  </MultiLangField>
                </span>
              </p>
            </div>

            {!courses && (
              <div className="w-full flex justify-center p-4">
                <Loader style={{ width: "130px" }} />
              </div>
            )}
            {courses && courses.length === 0 && (
              <div className="w-full flex justify-center p-4 text-2xl font-semibold text-gray-400">
                Couldn't fetch courses
              </div>
            )}
            {courses && courses.length > 0 && (
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-1 md:gap-4 mx-auto px-2 md:px-40">
                {courses.slice(0, 9).map(obj => (
                  <CourseCard data={obj} />
                ))}
              </div>
            )}

            <div className="max-w-max mx-auto mt-14 mb-28">
              <Link
                to={language == "en" ? "/courses" : "/" + language + "/courses"}
              >
                <button className="bg-japanese_indigo-light text-japanese_indigo font-semibold px-10 py-2 text-base rounded-lg">
                  <MultiLangField name={"topCourses_button"}>
                    View All
                  </MultiLangField>
                </button>
              </Link>
            </div>
          </section>
          <section className="career">
            <div className="bg-blue-grad relative py-12 lg:mx-16">
              <div
                className="text-center text-4xl font-semibold mb-10 blue-dark2 px-10"
                // dangerouslySetInnerHTML={{ __html: ExploreOtherCourses }}
              >
                {/* <p>
                <span className="blue-dark1">
                  Explore All Other Courses Across 12+
                  <br /> Sectors For An
                </span>
                <span className="red-dark">{} Attractive Career</span>
              </p> */}
                <p>
                  <span className="blue-dark1">
                    <MultiLangField name={"sectors_Title1"}>
                      Explore All Other Courses Across 12+
                    </MultiLangField>
                    <br />
                    <MultiLangField name={"sectors_Title2a"}>
                      Sectors For An{" "}
                    </MultiLangField>
                  </span>
                  <span className="red-dark">
                    <MultiLangField name={"sectors_Title2b"}>
                      Attractive Career
                    </MultiLangField>
                  </span>
                </p>
              </div>
              <div className="flex flex-wrap items-center justify-center pt-4 content mx-auto">
                {coursesByCategories
                  ?.slice(0, !active ? 12 : coursesByCategories.length)
                  .map((obj, catIndex) => {
                    let image =
                      (typeof obj.image === "string" && obj.image) ||
                      (obj.image &&
                        ((obj.image.formats &&
                          obj.image.formats.medium &&
                          obj.image.formats.medium.url) ||
                          obj.image.url))
                    return (
                      obj.categoryName && (
                        <Link to={"/courses/" + obj.id}>
                          <div className="relative m-3">
                            {((image || catIndex === 0) && (
                              <img
                                src={
                                  catIndex
                                    ? image
                                    : require("../assets/image/all-category.png")
                                        .default
                                }
                                className="height-career"
                              />
                            )) || (
                              <div
                                className="height-career"
                                style={{ background: "#444" }}
                              />
                            )}
                            <div className="career-option text-white bg-gray-800 bg-opacity-50 absolute top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-center">
                              <p className="text-xl font-semibold text-center">
                                {obj.categoryName}
                              </p>
                              <p className="text-md mt-2">
                                {obj.numOfCourses
                                  ? `${obj.numOfCourses}+`
                                  : obj.numOfCourses}{" "}
                                courses
                              </p>
                              <button className="btn-white">View Sector</button>
                            </div>
                          </div>
                        </Link>
                      )
                    )
                  })}
              </div>

              <div className="max-w-max mx-auto my-16">
                <button
                  className="bg-japanese_indigo-light text-sm text-japanese_indigo font-semibold p-4 rounded-lg"
                  onClick={() => setActive(!active)}
                >
                  {!active ? (
                    <MultiLangField name={"sectors_buttonAll"}>
                      Browse All Sectors
                    </MultiLangField>
                  ) : (
                    <MultiLangField name={"sectors_buttonFew"}>
                      Show Less
                    </MultiLangField>
                  )}
                </button>
              </div>
            </div>
          </section>
          <section className="mx-auto stacked-linear py-12 my-20 content">
            <div className="flex flex-col lg:flex-row items-center lg:justify-evenly">
              <div className="mx-10 w-110">
                <div className="p-2 w-full rounded-3xl relative bg-white">
                  <img src={Career} className="rounded-lg w-full" />
                </div>
                <div className="flex flex-col sm:flex-row items-center justify-between my-4 mb-2 text-sm">
                  <div className="bg-white rounded-xl font-semibold px-3 py-3 w-full sm:mr-2">
                    <MultiLangField name={"courseNameSWAR"}>
                      Spoken English Program
                    </MultiLangField>
                  </div>
                  <div className="bg-white rounded-xl px-3 py-1 sm:text-center w-full mt-4 sm:mt-0">
                    <p className="blue-dark2">
                      <MultiLangField name={"courseType"}>
                        English speaking course
                      </MultiLangField>
                    </p>
                    <p className="text">34,455 Students</p>
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row items-center justify-between">
                  <div className="py-3 px-2 bg-white rounded-xl flex items-center w-full sm:w-auto">
                    <p className="mx-2 text-sm">
                      <MultiLangField name={"swar_time"}>
                        3 months, 36 one Hour Live Sessions
                      </MultiLangField>
                    </p>
                  </div>
                  <div className="py-3 px-3 bg-white rounded-xl flex items-center w-full sm:w-auto sm:mt-0">
                    <p className="mx-2 text-sm">
                      <span className="red-dark">NSQF </span>{" "}
                      <span className="text">
                        <MultiLangField name={"level"}>Level</MultiLangField> 2
                      </span>
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-between my-3 bg-white p-3 rounded-xl">
                  <div className="flex items-center bg-orange text-white font-semibold rounded-lg p-3">
                    {/* <img src={Cart} className="h-8 w-8" /> */}
                    <a href={webappHost} target="_blank">
                      <button className="text-sm md:text-md px-5 font-semibold">
                        <MultiLangField name={"getEnrolled"}>
                          Get Enrolled
                        </MultiLangField>
                      </button>
                    </a>
                  </div>
                  <div className="flex items-center">
                    {/* <div className="flex items-center mr-2 relative max-w-max">
                      <div className="bg-green-600 h-1 w-20 absolute top-1/2 left-2 transform -rotate-12"></div>
                      <img src={RuppeeGray} className="h-4 w-4" />
                      <p className="text-sm text-gray-400 font-semibold">
                        <MultiLangField name={"swar_discount_price"}>
                          1200/month
                        </MultiLangField>
                      </p>
                    </div> */}

                    <div className="flex items-center">
                      <img src={Ruppee} className="h-6 w-6" />
                      <p className="text-lg green-dark font-semibold">
                        <MultiLangField name={"swar_actuall_price"}>
                          1200/month
                        </MultiLangField>
                      </p>
                    </div>
                  </div>
                </div>
                <button
                  className="my-1 w-full primary-button"
                  onClick={() =>
                    setPath("/course-details/" + englishStarCourseId)
                  }
                >
                  See more details
                </button>
              </div>
              <div className="mt-14 lg:mt-0 text-2xl text-gray-500 mx-10 sm:w-4/5 lg:w-2/5 shadow-2xl bg-white rounded-3xl line-text">
                {/* <div
                  className="bg-white rounded-3xl divide-y divide-gray-400 w-96 shadow-2xl "
                  dangerouslySetInnerHTML={{ __html: LiveSessionAvailable }}
                >
                </div> */}
                <img
                  className="rounded-t-xl"
                  src={EnglishStarLogo}
                  alt="English-star-logo"
                />
                <p className="text-orange text-2xl md:text-3xl font-semibold my-2 px-6">
                  <MultiLangField name={"swar_learn"}>
                    Learn Spoken English
                  </MultiLangField>
                </p>
                <p className="text-orange text-2xl md:text-3xl font-semibold my-2 px-6">
                  in 3 Months & 36 Hrs
                </p>
                <p className="border-t border-b p-4 text-base mt-3">
                  <MultiLangField name={"swar_Knowledge"}>
                    Speak, Write, Acknowledge & Read English
                  </MultiLangField>
                </p>
                <p className="p-4 text-base">
                  <MultiLangField name={"swar_description"}>
                    36 Live 1 hour Training Sessions With Ceritified Spoken
                    English Experts from Delhi, Mumbai, Bangalore, Kolkata Etc.
                  </MultiLangField>
                </p>
                <p className="border-t border-b p-4 text-base">
                  <MultiLangField name={"swar_benefit"}>
                    Unlimited Access to 100+ Hrs Digital Content For 1 Year
                  </MultiLangField>
                </p>
                <p className="p-4 text-base">
                  <MultiLangField name={"swar_map"}>
                    Course mapped to Common European Framework of Reference
                    (CEFR) and English for Employability, NSQF Level 2 of Govt.
                    of India
                  </MultiLangField>
                </p>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </MultiLangBody>
    // </TinaProvider>
  )
}

export const sortEnglishCourse = courses => {
  // Sorting english course
  const englishCourse = courses.find(e => e.courseId === englishStarCourseId)

  if (englishCourse) {
    const index = courses.indexOf(englishCourse)
    courses.splice(index, 1)
    courses.splice(0, 0, englishCourse)
  }

  return courses
}

export default IndexPage
