const { makeGraphRequest } = require("../utils/request")

class InstructorService {
  getTotalInstructors() {
    return makeGraphRequest(`{
    courses_moodle_courses__user_stats (where: {
      moodle_course_id: { _in: ["83", "75","78", "88"] }
    }) {
      total_users
    }
    courses_instructor_aggregate{
      aggregate{
        count
      }
    }
  }`).then(res => {
      return (
        res.courses_moodle_courses__user_stats.reduce(
          (total, trainer_course) => total + trainer_course.total_users,
          0
        ) + res.courses_instructor_aggregate.aggregate.count
      )
    })
  }
}

const InstructorSvc = new InstructorService()

module.exports = InstructorSvc
