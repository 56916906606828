const { makeGraphRequest } = require("../utils/request")

class UserService {
  getTotalUsers() {
    return makeGraphRequest(`{
      courses_moodle_user_stats (
        where: {
          moodle_name: { _neq: "Skillstrainer" }
        }
      ) {
        total_moodle_users
      }

      courses_users_aggregate {
        aggregate {
          count
        }
      }
    }`).then(
      ({
        courses_moodle_user_stats: moodleRes,
        courses_users_aggregate: {
          aggregate: { count: stUserCount },
        },
      }) =>
        moodleRes.reduce(
          (total, cur) => total + Number(cur.total_moodle_users),
          0
        ) + stUserCount
    )
  }
}

const userSvc = new UserService()

module.exports = userSvc
