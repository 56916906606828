import React, { useEffect, useState, useContext } from "react"
import { webappHost } from "../../config"
import { StateContext } from "../context/stateProvider"
import LanguageSwitcher from "./LanguageSwitcher"
import { MultiLangField } from "skillstrainer-resource-library"

const BottomNav = ({ children }) => {
  const { language, setLanguage } = useContext(StateContext)

  return (
    <>
      <div className="block xl:hidden">
        <div className="bg-white fixed bottom-0 left-0 right-0 flex items-center border-t border-gray-400 z-20">
          <div className="w-4/12">
            <div className=" w-full border-r border-gray-400">
              <LanguageSwitcher direction="up" dropdown={true} />
            </div>
          </div>
          <div>
            <button className="text-sm ml-6 bg-orange text-white font-semibold rounded-lg py-2 px-4">
              <a href={webappHost + "/login"} target="_blank">
                <MultiLangField name={"login"}>Login</MultiLangField>
              </a>
            </button>
          </div>
          <div className="w-2/5 ml-4">
            <a href={webappHost} target="_blank">
              <button className="bg-orange text-white text-sm font-semibold rounded-lg px-4 py-2">
                Join For Free
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default BottomNav
